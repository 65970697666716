@font-face {
  font-family: "LocalRoboto";
  src: url("/fonts/Roboto-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: "LocalRoboto";
  src: url("/fonts/Roboto-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "LocalRoboto";
  src: url("/fonts/Roboto-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "LocalRoboto";
  src: url("/fonts/Roboto-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "LocalRoboto";
  src: url("/fonts/Roboto-Black.ttf");
  font-weight: 900;
}
/**
 * SELECTORS
 */
/**
 * COLORS
 */
/**
 * S2 COLORS
 */
/**
 * HIGHLIGHTS
 */
/**
 * TEXT STYLING
 */
/**
 * BORDERS
 */
/**
 * LABELS
 */
/**
 * FONT SIZES
 */
/**
 * SPACE
 */
/**
 * ELEMENT DIMENSIONS
 */
/**
* Z INDEXES
* 100-based z-indexes are chosen to work with Material UI's z-indexes:
* https://material-ui.com/customization/z-index/
*/
/**
 * PDF VIEWER APPLICATION VARIABLES
 * The syntax for each of these variables is:
 *
 * 'pdfjs-<sanitized-selector>-<css-property-name>: <css-value>
 *
 * XXX(andrewhead): If the pdf.js submodule is updated, these properties must be checked to
 * ensure that they still match those in the pdf.js style files.
 */
html {
  font-family: "LocalRoboto", Roboto, "Helvetica Neue", Arial, sans-serif;
  /*
   * This is the default root font size used on the Material UI demo site. It seems to be necessary
   * to make the default padding values and sizes of components look right. This value conflicts with
   * the value set in the pdf.js code, which might cause visual problems.
   * https://github.com/mozilla/pdf.js/blob/7dabc5ecc8347fbda8ce4a219c182790d9ec9187/web/viewer.css#L32-L33
   * If it does, styles will either need to be set manually for the Material UI components used by this
   * app, or the pdf.js source code should be changed to reflect this font size.
   */
  font-size: 0.875rem;
}
/**
 * Position annotations absolutely on each page
 */
.scholar-reader-annotation-span {
  position: absolute;
}
/**
 * Enlarge the hitbox of an annotation
 */
.scholar-reader-annotation-span {
  margin: -0.06666667em -0.1em;
  padding: 0.06666667em 0.1em;
}
/**
 * Make annotations interactive
 */
.scholar-reader-annotation {
  /**
   * Highlight all spans of an annotation when hovering over any one span of the annotation
   */
  /**
   * Deepen the saturation of colors when the annotation is selected
   */
}
.scholar-reader-annotation:hover .scholar-reader-annotation-span {
  background-color: rgba(19, 92, 202, 0.2);
  cursor: pointer;
}
.scholar-reader-annotation.selected .scholar-reader-annotation-span:not(.find-selection) {
  background-color: rgba(19, 92, 202, 0.3);
}
.scholar-reader-annotation .scholar-reader-annotation-span.underline:not(.ancestor-of-selection) {
  padding-bottom: 0.2%;
  border-bottom: 1px rgba(51, 51, 51, 0.7) dotted;
}
/**
 * Highlight matches from search
 */
.scholar-reader-annotation-span.find-match {
  background-color: rgba(19, 92, 202, 0.2);
}
.scholar-reader-annotation-span.find-selection {
  background-color: rgba(19, 92, 202, 0.3) !important;
}
/**
 * Highlight the target of jumps within the document
 */
.scholar-reader-annotation-span.jump-target {
  background-color: rgba(19, 92, 202, 0.2);
}
@keyframes fade {
  0% {
    background-color: rgba(19, 92, 202, 0.2);
  }
  75% {
    background-color: rgba(19, 92, 202, 0.2);
  }
  100% {
    background-color: none;
  }
}
/**
 * Make inactive annotations non-interactive
 */
.scholar-reader-annotation-span.inactive {
  pointer-events: none;
}
/**
 * Highlight entities that were extracted using experimental techniques
 */
.scholar-reader-annotation-span.source-other {
  background-color: rgba(255, 255, 0, 0.3);
}
/**
 * Set z-index of annotation spans to minimize occlusion. For example, sentences annotations will
 * often contain the full area of other annotations. To allow the other annotations to be
 * clickable, show sentence annotations under other annotations.
 */
.scholar-reader-annotation-span.sentence-annotation {
  z-index: 1000;
}
.scholar-reader-annotation-span.equation-annotation {
  z-index: 1001;
}
.scholar-reader-annotation-span:not(.sentence-annotation) {
  z-index: 1002;
}
/**
 * Symbol annotations
 */
.symbol-annotation.selected:not(.leaf-symbol) {
  pointer-events: none;
  padding: 0.4rem;
  margin: -0.4rem;
  border-radius: 3px;
  background-color: rgba(19, 92, 202, 0.2) !important;
}
.symbol-annotation.selection-hint {
  background-color: rgba(19, 92, 202, 0.3);
  border-radius: 3px;
  /*
     * By default, annotations appear behind equation diagrams, which are meant to appear
     * as overlays on top of the annotations. However, when an equation diagram and a
     * symbol is meant to be clickable, its z-index needs to be higher than the z-index
     * for the diagram.
     */
}
.symbol-annotation.selection-hint.in-selected-equation {
  z-index: 1005;
}
/**
 * Equation annotations
 */
.equation-annotation.annotation-selected {
  pointer-events: none;
  background: none !important;
  border-bottom: none !important;
}
.citation {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
/**
 * Title style
 */
.citation .title {
  font-weight: bolder;
}
/**
 * Abstract style
 */
.citation__abstract {
  display: block;
  margin: 0.5rem 0 0;
}
/**
 * Link to sidebar
 */
.citation__abstract__sidebar-link,
.citation__abstract__sidebar-link:hover,
.citation__abstract__sidebar-link:visited:hover {
  color: #5492ef;
  text-decoration: none;
}
.citation__abstract__sidebar-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
/**
 * Button for voting on usefulness
 */
.citation-gloss__vote-button {
  position: absolute;
  right: 1.6rem;
  top: 1.6rem;
}
/**
 * Control panel padding
 */
.scholar-reader-toolbar.control-panel-toolbar {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}
/**
 * Control panel layout of components
 */
.control-panel-toolbar .control-panel-toolbar__header {
  margin-bottom: 0.4rem;
}
.control-panel-toolbar .control-panel-toolbar__header div {
  display: inline-block;
}
.control-panel-toolbar .control-panel-toolbar__header div.control-panel-toolbar__header__close-button {
  float: right;
}
.control-panel-toolbar .control-panel-toolbar__select {
  margin-top: 0.8rem;
  margin-bottom: 0.4rem;
}
/**
 * Control panel switches
 */
.control-panel-toolbar__switch-label {
  white-space: nowrap;
}
.document-snippets {
  width: 300px;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.document-snippets > * {
  margin-top: 0.8rem;
}
.document-snippets .entity-link-message {
  text-align: center;
  margin-bottom: 1.6rem;
}
/**
 * Formulas
 */
.defining-formula {
  border: 1px #ddd solid;
  border-radius: 3px;
  padding: 0.8rem;
  /*
   * Interaction
   */
  /*
   * Some equations will be massive, so they scroll.
   */
  max-height: 5rem;
  overflow: hidden;
  /*
   * Don't wrap the text, and center the span if it doesn't take up the whole width.
   */
}
.defining-formula.clickable {
  cursor: pointer;
}
.defining-formula .formatted-text {
  white-space: pre;
  display: table;
  margin: 0 auto;
}
.snippet {
  border: 1px #ddd solid;
  border-radius: 3px;
  padding: 0.8rem;
  overflow: hidden;
  font-size: 1rem;
  /*
   * Give lines in snippets a bit of breathing room, as usages frequently
   * inline equations that will make lines taller. By increasing the line
   * height, the line spacing of snippets looks more consistent.
   */
  line-height: 1.5em;
}
/*
 * Links within the document
 */
.entity-page-link {
  white-space: nowrap;
}
.entity-link-span.clickable {
  text-decoration: underline;
  cursor: pointer;
  color: #5492ef;
}
.entity-link-span.clickable.subtle {
  color: #5492ef;
  text-decoration: none;
}
/**
 * Drawer position
 */
.drawer .MuiPaper-root {
  top: 32px;
  height: calc(100% - 32px);
}
/**
 * Drawer style
 */
.drawer .MuiPaper-root {
  border-left: 1px #ddd solid;
}
.drawer__header {
  padding: 1rem;
}
/**
 * Padding for drawer content.
 */
.drawer__content {
  padding: 0 0.8rem;
}
.drawer__close_icon {
  position: fixed;
  z-index: 1200;
}
/**
 * Drawer text.
 */
.drawer__content__header {
  font-size: 1.2rem;
  font-weight: bolder;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
p:not(.drawer__content__header) {
  font-size: 1rem;
  line-height: 1.2rem;
  color: #2e414f;
}
/**
 * Alignment of entity creation widgets
 */
.entity-creation-toolbar .entity-creation-toolbar__action-button,
.entity-creation-toolbar .entity-creation-toolbar__selection-message {
  vertical-align: bottom;
}
/**
 * Entity creation widget fonts
 */
.entity-creation-toolbar__selection-message {
  font-size: 1rem;
}
/**
 * Property editor dimensions.
 */
.entity-property-editor {
  width: 400px;
}
/**
 * Padding between sections.
 */
.entity-property-editor > p,
.entity-property-editor > div {
  margin-bottom: 1.6rem;
}
.entity-property-field-container {
  margin-bottom: 1.6rem;
}
.entity-property-field-container .entity-property-field {
  margin-bottom: 0.4rem;
}
/**
 * Formatting of rows (field + add/delete buttons) for list-type properties.
 */
.entity-property-field-row {
  display: flex;
  flex-wrap: wrap;
}
.entity-property-field-row .entity-property-field {
  display: flex;
  flex: 1 1 auto;
}
/**
 * Span formats.
 */
.entity-property-field-container .action-span {
  color: deepskyblue;
  text-decoration: underline;
  cursor: pointer;
}
/**
 * Buttons for entity fields.
 */
div.entity-property-editor__action-form {
  margin-top: 3.2rem;
}
div.entity-property-editor__action-form > * {
  width: 100%;
  margin-bottom: 0.8rem;
}
/**
 * Error messages.
 */
.entity-property-editor .entity-property-editor__save-error-label {
  color: #ff0000;
}
p.entity-property-input-error {
  font-size: 0.8rem;
  color: #ff0000;
}
/**
 * LaTeX previews.
 */
.rich-text-preview {
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0.4rem;
  margin-bottom: 0.4rem;
}
.rich-text-preview__container {
  overflow-x: auto;
  overflow-y: hidden;
}
/**
 * "Preview" label in LaTeX preview.
 */
.rich-text-preview__preview-label {
  float: right;
  background-color: white;
  font-size: 0.8rem;
  color: #8c9296;
}
/**
 * Equation diagram.
 */
.equation-diagram {
  z-index: 1004;
}
/**
 * Features (areas of interest) in diagrams.
 */
rect.equation-diagram__feature {
  stroke-width: 2;
  fill: #135cca;
  fill-opacity: 0.2;
}
/**
 * Labels
 */
div.equation-diagram-tooltip {
  border: 1px solid #bbb;
}
div.equation-diagram-gloss {
  cursor: pointer;
  max-width: 200px;
}
div.equation-diagram-gloss:hover {
  background-color: #ddd;
}
/**
 * Leader lines.
 */
g.equation-diagram__leader {
  opacity: 0.8;
}
g.equation-diagram__leader:hover {
  opacity: 1;
}
g.equation-diagram__leader path.leader-background {
  stroke: white;
  stroke-width: 4;
  fill: none;
}
g.equation-diagram__leader path.leader-foreground {
  stroke: black;
  stroke-opacity: 0.4;
  stroke-width: 2;
  fill: none;
}
/**
 * Hover behavior.
 */
div.equation-diagram__label-container {
  pointer-events: all;
  cursor: pointer;
}
div.equation-diagram__label-container:hover {
  cursor: pointer;
}
div.equation-diagram__label-container:hover rect.equation-diagram__feature {
  fill-opacity: 0.4;
}
div.equation-diagram__label-container:hover .equation-diagram-gloss {
  background-color: #ddd;
}
div.equation-diagram__label-container:hover path.leader-foreground {
  stroke-opacity: 0.7;
}
/**
 * Favorite button placement
 */
.favorite-button {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
}
/**
 * Optional opaque configuration
 */
.favorite-button--opaque {
  background-color: #fff;
}
.favorite-button--opaque .MuiIconButton-root {
  border: 1px #ddd solid;
}
/**
 * Container for a favorite must have relative positioning
 */
.favorite-container {
  position: relative;
}
.favorite {
  margin-top: 0.8rem;
}
/**
 * Styles for the <FeedbackButton />
 */
.MuiButtonBase-root.feedback-button {
  padding: 0.25rem;
  width: auto;
  min-width: auto;
  border-radius: 50%;
  line-height: 1;
  color: #8c9296;
  float: right;
}
/**
 * We render the <FeedbackButton /> into the PDF.js's default toolbar.
 * This selector provides styles specific to that context.
 */
.toolbar__feedback-button {
  display: flex;
  align-items: center;
  width: auto;
  min-width: auto;
}
.toolbar__feedback-button > span {
  width: auto;
  height: auto;
  display: block;
  white-space: nowrap;
  margin-left: 0.5rem;
}
/**
 * For some reason PDF.js's viewer doesn't add the `:cursor` style to the
 * toolbar buttons. We add that for consistency with other clickable UI
 * elements.
 */
.toolbarButton {
  cursor: pointer;
}
/**
 * Find-bar is opaque
 */
.find-bar {
  background-color: #fff;
  /* 
   * Annotation markers should be hidden behind the find bar
   */
  z-index: 1006;
}
/**
 * Layout of find-bar widgets
 */
.find-bar > * {
  display: inline-block;
}
.find-bar__progress,
.find-bar__message__span {
  vertical-align: middle;
}
.find-bar .find-bar__query__flag {
  margin-left: 0.8rem;
  margin-right: 0;
}
/**
 * Find bar fonts
 */
.find-bar {
  color: #2e414f;
}
.find-bar__message__span {
  font-size: 1rem;
}
/**
 * Symbol search filters
 */
.find-bar .symbol-filters .filter-button + * {
  margin-left: 0.8rem;
}
/**
 * Default gloss dimensions
 */
.gloss {
  max-width: 420px;
}
.gloss:not(.simple-gloss) {
  min-width: 200px;
}
.gloss.closed {
  display: none;
}
/**
 * Gloss contents
 */
.gloss {
  /*
   * Inset nested list elements
   */
  list-style-position: inside;
  /*
   * If this is an inline gloss, lay out sections horizontally.
   */
}
.gloss.simple-gloss {
  background-color: #eee;
  padding: 0;
}
.gloss.simple-gloss.symbol-gloss .simple-gloss__definition-container {
  width: max-content;
  max-width: 350px;
}
.gloss.simple-gloss.term-gloss .simple-gloss__definition-container {
  width: max-content;
  max-width: 350px;
}
.gloss.simple-gloss p {
  padding: 0.2rem;
  margin-top: 0;
  font-size: 1.2rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  line-height: 1.2;
}
.gloss.simple-gloss td {
  vertical-align: middle;
}
.gloss.simple-gloss.term-gloss p {
  padding: 0.4rem;
  max-height: 5rem;
  overflow: auto;
}
.gloss .gloss__section + .gloss__section {
  padding-top: 0.5rem;
}
.gloss .gloss__header {
  padding-top: 0.4rem;
}
.gloss.inline-gloss > div {
  display: inline-block;
  vertical-align: middle;
}
/**
 * Align buttons to the right
 */
.gloss__action-buttons {
  text-align: right;
}
/**
 * Space out the action buttons
 */
.gloss__action-buttons .gloss__action-button {
  margin-left: 0.8rem;
}
/**
 * Gloss text
 */
.gloss {
  color: #2e414f;
  font-family: "LocalRoboto", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 300;
  /*
   * List element styling
   */
}
.gloss li {
  margin-bottom: 0.5rem;
  /*
     * Show display equations inline
     */
}
.gloss li .katex-display {
  margin: unset;
}
.gloss .gloss__section > p {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  margin-bottom: 0.4rem;
}
.gloss .gloss__section > p:not(:first-child) {
  margin-top: 0.4rem;
}
.gloss p {
  margin-top: 0.4rem;
}
.gloss p.gloss-section__label {
  font-size: small;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0.8rem;
}
.gloss p span.gloss__show-more {
  cursor: pointer;
  border-bottom: 1px solid #5492ef;
  color: #5492ef;
}
.gloss span.other-symbol-link {
  padding-left: 0.1rem;
  cursor: pointer;
  border-bottom: 1px solid #5492ef;
  color: #5492ef;
}
/**
 * Fonts
 */
.gloss-entry-table__clickable-link {
  color: #5492ef;
  text-decoration: underline;
  cursor: pointer;
}
/**
 * Property evaluation tables
 */
.gloss-entry-table {
  table-layout: fixed;
  /**
   * Cell dimensions
   */
}
.gloss-entry-table td {
  padding-left: 0.4rem;
  border-bottom: none;
}
.gloss-entry-table td:first-of-type {
  /*
       * Scroll display equations
       */
  width: 90%;
  padding-right: 0.4rem;
  overflow-x: auto;
  overflow-y: hidden;
}
.gloss-entry-table td.vote-button {
  width: 24px;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.gloss-entry-table__action-buttons-row td {
  text-align: center;
}
.gloss-entry-table__action-buttons-row button {
  text-transform: none;
}
/**
 * Font styles for formatted text
 */
span.formatted-text .highlight {
  font-weight: bold;
  background-color: rgba(19, 92, 202, 0.2);
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  border-radius: 3px;
}
span.formatted-text .hide {
  color: #8c9296;
}
span.formatted-text .match-highlight {
  background-color: rgba(19, 92, 202, 0.2);
}
/**
 * MathML equation
 */
.mathml {
  display: inline;
}
/**
 * Even if in a Material UI button, don't force it to upper case.
 */
.MuiButton-root .mathml {
  text-transform: none;
}
/**
 * This file contains global overrides of MaterialUI default styles. Styles in this file can,
 * for example, change the font sizes of Material UI snackbars and tooltips globally.
 */
div[role=tooltip] .MuiTooltip-tooltip {
  font-size: 1rem;
}
.MuiSnackbar-root.MuiSnackbar-anchorOriginTopLeft {
  top: 42px !important;
}
.MuiSnackbarContent-message {
  font-size: 1.2rem;
}
/**
 * Position page overlay on top of its parent page.
 */
.scholar-reader-page-overlay {
  position: absolute;
  top: 0;
  left: 0;
  /**
   * The overlay is full width and height to allow children to also be full width and full height
   * (i.e., to set width and height to 100% in the children to make them take up the whole page).
   * To keep the underlying content on the page still interactive, 'pointer-events' is set to 'none'
   * so that the overlay doesn't inherently capture clicks. That said, all children will be
   * interactive (see 'pointer-events: auto' below) by default.
   */
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.scholar-reader-page-overlay * {
  pointer-events: auto;
}
/**
 * Page mask
 */
.page-mask__highlight {
  fill: #135cca;
  opacity: 0.3;
}
.page-mask__highlight-label {
  font-family: "LocalRoboto", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #2e414f;
}
.page-mask__highlight-label__background {
  fill: #fff49e;
  stroke: #222;
  stroke-width: 1px;
}
/**
 * Container for paper clipping.
 */
/**
 * Paper clipping dimensions
 */
.paper-clipping {
  /**
   * In a tooltip.
   */
  border: 1px #ddd solid;
  border-radius: 3px;
  /**
   * Set to 'auto' if you want users to be able to scroll.
   */
  overflow: hidden;
}
.symbol-tooltip-body .paper-clipping {
  width: 320px;
  height: 120px;
}
/**
 * Paper summary layout
 */
.paper-summary {
  max-width: 400px;
  padding: 0.8rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  color: #2e414f;
}
/**
 * Pad paper summary title so the favorite button doesn't occlude it
 */
.paper-summary__title {
  padding-right: 2em;
}
/**
 * Paper summary fonts
 */
.paper-summary {
  color: #222;
  font-size: 1rem;
  line-height: 1.2rem;
}
.paper-summary__section {
  padding-bottom: 0.4rem;
}
.paper-summary__title {
  font-size: 1.2rem;
  line-height: 1.44rem;
  font-weight: bold;
}
.paper-summary__authors,
.paper-summary__year {
  color: #3c3c3c;
}
.paper-summary__abstract__show-more-label {
  color: deepskyblue;
}
.paper-summary__feedback {
  color: #a2a2a2;
  font-style: italic;
}
.paper-summary__metrics-and-actions {
  display: flex;
  align-items: center;
}
/**
 * Actions
 */
.MuiButtonBase-root.paper-summary__action {
  color: #8c9296;
}
.MuiButtonBase-root.paper-summary__action svg {
  font-size: 1.8rem;
}
.paper-summary__library-error {
  color: #ff0000;
}
/**
 * Metrics
 */
.paper-summary__metrics {
  display: flex;
  border-right: 1px solid #e0e0e0;
  margin-right: 0.8rem;
}
.paper-summary__metrics__metric {
  display: flex;
  align-items: center;
  margin-right: 0.8rem;
  color: #dd913f;
}
.paper-summary__metrics__metric svg {
  fill: #dd913f;
  margin-right: 0.4rem;
}
.MuiButtonBase-root.paper-summary__action,
.paper-summary__metrics__metric {
  font-size: 0.8rem;
  font-weight: 700;
}
.paper-summary__abstract__show-more-label {
  cursor: pointer;
}
/**
 * Move view of PDF page inward when the drawer is open.
 * TODO(andrewhead): Find a way to animate drawer even when left sidebar is open.
 */
#viewerContainer {
  transition-property: left, right;
}
#viewerContainer.drawer-entity-property-editor {
  right: 400px;
}
#viewerContainer.drawer-usages,
#viewerContainer.drawer-defining-formulas,
#viewerContainer.drawer-definitions {
  right: 300px;
}
/**
  * Hide default paper citation annotations generated by pdf.js to make the citation tooltip the default behavior.
  */
.linkAnnotation a[href^="#cite"] {
  display: none;
}
/**
 * Remove border boxes added by hyperref, which conflict with our styles. Because pdf.js sets border
 * styles dynamically, the '!important' flag is used to get sufficient precedence to override
 * border styles. See the pdf.js code setting border styles here:
 * https://github.com/mozilla/pdf.js/blob/474fe1757e654e67d20a53774edb293aa073ce0b/src/display/annotation_layer.js#L19
 */
.annotationLayer .linkAnnotation {
  border: none !important;
  display: none !important;
}
/**
 * Make highlights for the pdf.js find bar consistent with the rest of the application.
 */
.textLayer .highlight {
  background-color: rgba(19, 92, 202, 0.7);
}
.textLayer .highlight.selected {
  background-color: #135cca !important;
}
/**
  * Consistent default behavior between pdf.js highlight for tables, figures, etc.
  * and custom purple highlight for citation annotations.
  */
.annotationLayer .linkAnnotation > a {
  border-bottom: 1px #333 dotted;
}
.annotationLayer .linkAnnotation > a:hover,
.annotationLayer .linkAnnotation > a.selected {
  background: #5492ef;
  opacity: 0.2;
  box-shadow: 0px 2px 10px #5492ef;
}
/**
 * Style buttons added to the pdf.js toolbar.
 */
.pdfjs-toolbar__button {
  display: flex;
  align-items: center;
  width: auto;
  min-width: auto;
}
.pdfjs-toolbar__button > span {
  width: auto;
  height: auto;
  display: block;
  white-space: nowrap;
}
/**
 * The base styles for a primer page are the same as those for a pdf.js page:
 * https://github.com/mozilla/pdf.js/blob/67c361b2f4b2ff3ee1c8a90ace643b769e97b85f/web/pdf_viewer.css#L22-L33
 */
.primer-page {
  background-color: white;
  margin: 1px auto -8px auto;
  border: 9px solid transparent;
  background-clip: content-box;
}
/**
 * Layout of content within the primer
 */
.primer-page__contents {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 4.8rem;
  padding-right: 4.8rem;
  /*
   * Distance between child elements
   */
  /*
   * Arrange glossary in columns
   */
}
.primer-page__contents > * {
  margin-bottom: 1rem;
}
.primer-page__contents > * + .primer-page__header {
  padding-top: 1rem;
}
.primer-page__contents ul.feature-list li {
  margin-left: 1.5em;
  margin-bottom: 0.3em;
}
.primer-page__contents a {
  text-decoration: none;
  color: #5492ef;
}
.primer-page__contents a:hover {
  color: #135cca;
  text-decoration: underline;
}
.primer-page__contents a:visited {
  color: #654cb3;
}
.primer-page__contents a:visited:hover {
  color: #3d2e6b;
}
.primer-page__contents .primer-page__glossary .gloss {
  width: unset;
  min-width: unset;
  max-width: unset;
  padding: unset;
  margin-bottom: 0.8rem;
}
.primer-page__contents .primer-page__glossary ul {
  column-count: 3;
}
.primer-page__contents hr {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}
.welcome-page {
  font-family: "Palatino Linotype", "Palatino", "EB Garamond", "Times New Roman", "Times", serif;
}
/**
 * Primer fonts
 */
.primer-page__contents {
  font-weight: lighter;
  font-size: 1rem;
  color: #2e414f;
}
.primer-page__contents .primer-page__header {
  font-size: 1.2rem;
  font-weight: bold;
}
.primer-page__contents .primer-page__subheader {
  font-weight: bolder;
}
.primer-page__glossary {
  /*
   * Show glossary entries in columns
   */
}
.primer-page__glossary .gloss {
  width: unset;
  padding: unset;
  font-weight: 300;
}
.primer-page__glossary ul {
  /*
     * Don't split an glossary entry between columns. See
     * https://stackoverflow.com/a/7785711/2096369.
     */
}
.primer-page__glossary ul li {
  list-style: none;
  margin-right: 0.8rem;
}
.primer-page__glossary ul li {
  break-inside: avoid-page;
}
.primer-page__glossary ul li .gloss {
  display: block;
  /*
         * Prevents fragmentation of absolutely-positioned children
         * (which is necessary to render KaTeX equations properly). See
         * https://stackoverflow.com/a/57976866/2096369
         */
  transform: translate(0, 0);
}
/**
 * Gloss dimensions
 */
.gloss.term-property-evaluation-gloss,
.gloss.symbol-property-evaluation-gloss {
  max-width: 400px;
}
/**
 * Fonts
 */
.property-evaluation-gloss__clickable-link {
  color: #5492ef;
  text-decoration: underline;
  cursor: pointer;
}
.property-evaluation-gloss .property-evaluation-gloss__not-defined {
  color: #8c9296;
}
/**
 * Property evaluation tables
 */
.property-evaluation-gloss table {
  table-layout: fixed;
  /**
   * Section headers
   */
  /**
   * Cell dimensions
   */
}
.property-evaluation-gloss table .property-evaluation-gloss__header {
  background-color: lightgrey;
}
.property-evaluation-gloss table .property-evaluation-gloss__header td {
  font-weight: bold;
}
.property-evaluation-gloss table td {
  padding-left: 0.4rem;
}
.property-evaluation-gloss table td:first-of-type {
  /*
       * Scroll display equations
       */
  width: 85%;
  overflow-x: auto;
  overflow-y: hidden;
}
.property-evaluation-gloss table td.vote-button {
  width: 24px;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
/**
 * Symbol tabs
 */
.MuiTab-root.symbol-property-evaluation-gloss__tab {
  min-width: 50px;
  width: 50px;
  text-transform: none;
}
/**
 * Styles for the <S2Link /> component.
 */
a[href].s2-link,
a[href].s2-link:hover,
a[href].s2-link:visited:hover {
  color: #5492ef;
  text-decoration: none;
}
a[href].s2-link:hover {
  text-decoration: underline;
}
a[href].s2-link:visited {
  color: #654cb3;
}
/**
 * Canvas for making selections should fill parent (likely the page overlay).
 */
.selection-layer {
  width: 100%;
  height: 100%;
}
/**
 * Cursor for selection layer
 */
.selection-layer {
  cursor: crosshair;
}
.selection-layer.wait {
  cursor: wait;
}
/**
 * Position selections relative to selection canvas.
 */
.selection {
  position: absolute;
}
/**
 * Selection color.
 */
.selection {
  background-color: rgba(0, 0, 0, 0.15);
  border: 1px dashed black;
}
/**
 * Position side note off the side of the page. Vertical position is set in the JavaScript.
 */
.scholar-reader-sidenote {
  position: absolute;
}
.scholar-reader-sidenote.side-left {
  right: 100%;
  margin-right: 0.8rem;
}
.scholar-reader-sidenote.side-right {
  left: 100%;
  margin-left: 0.8rem;
}
/**
 * Rule from sidenote to anchor
 */
.sidenote-rule {
  position: absolute;
  border: none;
  border-top: 1px dashed #222;
}
/**
 * Tooltip position
 */
.scholar-reader-tooltip {
  position: absolute;
  z-index: 1004;
}
/**
 * Tooltip background
 */
.scholar-reader-tooltip {
  background-color: #fff;
  color: #2e414f;
}
.scholar-reader-tooltip .citation-tooltip-body {
  background-color: #fff;
}
/**
 * Tooltip shadow
 */
.scholar-reader-tooltip {
  border: 1px #ddd solid;
}
/**
 * Position of container of toolbars
 */
.scholar-reader-toolbar-container {
  position: absolute;
  right: 1.6rem;
  top: 42px;
  /*
   * Uncomment these lines if the toolbars should appear at the bottom of the viewer instead of
   * the top.
   */
  /*
   * Toolbars must appear both above the pdf.js sidebar and annotations
   */
  z-index: 1006;
}
/**
 * Toolbars should receive pointer events, but the empty space surrounding them in the toolbar
 * container should not. If 'pointer-events' is not 'none' for the container, some annotations
 * may appear unclickable.
 */
.scholar-reader-toolbar-container {
  pointer-events: none;
}
.scholar-reader-toolbar-container div.scholar-reader-toolbar {
  pointer-events: all;
}
/**
 * Spacing between toolbars
 */
.scholar-reader-toolbar-container .scholar-reader-toolbar + .scholar-reader-toolbar {
  margin-top: 0.8rem;
}
/**
 * Show each toolbar on a separate line, and set their widths independently
 */
.scholar-reader-toolbar-container .scholar-reader-toolbar {
  float: left;
  clear: left;
}
/**
 * Toolbar contents
 */
div.scholar-reader-toolbar {
  padding: 0.4rem 0.8rem;
}
div.scholar-reader-toolbar:not(.vertical) > * + * {
  margin-left: 0.8rem;
}
/**
 * Definition preview position
 */
.definition-preview {
  position: absolute;
  bottom: 1.6rem;
  right: 1.6rem;
  z-index: 1005;
}
/**
 * Text selection menu
 */
.text-selection-menu {
  position: absolute;
  z-index: 1005;
  padding: 0.8rem;
  /*
   * Button text in sentence case
   */
}
.text-selection-menu button {
  text-transform: none;
}
