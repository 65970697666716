/**
 * Move view of PDF page inward when the drawer is open.
 * TODO(andrewhead): Find a way to animate drawer even when left sidebar is open.
 */
#viewerContainer {
  transition-property: left, right;

  &.drawer-entity-property-editor {
    right: @entity-property-editor-width;
  }

  &.drawer-usages,
  &.drawer-defining-formulas,
  &.drawer-definitions {
    right: @document-snippets-container-width;
  }
}

/**
  * Hide default paper citation annotations generated by pdf.js to make the citation tooltip the default behavior.
  */
.linkAnnotation a[href^="#cite"] {
  display: none;
}

/**
 * Remove border boxes added by hyperref, which conflict with our styles. Because pdf.js sets border
 * styles dynamically, the '!important' flag is used to get sufficient precedence to override
 * border styles. See the pdf.js code setting border styles here:
 * https://github.com/mozilla/pdf.js/blob/474fe1757e654e67d20a53774edb293aa073ce0b/src/display/annotation_layer.js#L19
 */
.annotationLayer .linkAnnotation {
  border: none !important;
  display: none !important;
}

/**
 * Make highlights for the pdf.js find bar consistent with the rest of the application.
 */
.textLayer .highlight {
  background-color: fade(@highlight-color, 70%);

  &.selected {
    background-color: @highlight-color !important;
  }
}

/**
  * Consistent default behavior between pdf.js highlight for tables, figures, etc.
  * and custom purple highlight for citation annotations.
  */
.annotationLayer .linkAnnotation > a {
  border-bottom: @underline-width @underline-color dotted;

  &:hover,
  &.selected {
    background: @highlight-hover-background;
    opacity: @highlight-hover-opacity;
    box-shadow: 0px 2px 10px @highlight-hover-background;
  }
}

/**
 * Style buttons added to the pdf.js toolbar.
 */
.pdfjs-toolbar__button {
  display: flex;
  align-items: center;
  width: auto;
  min-width: auto;

  > span {
    width: auto;
    height: auto;
    display: block;
    white-space: nowrap;
  }
}
