/**
 * Canvas for making selections should fill parent (likely the page overlay).
 */
.selection-layer {
  width: 100%;
  height: 100%;
}

/**
 * Cursor for selection layer
 */
.selection-layer {
  cursor: crosshair;

  &.wait {
    cursor: wait;
  }
}

/**
 * Position selections relative to selection canvas.
 */
.selection {
  position: absolute;
}

/**
 * Selection color.
 */
.selection {
  background-color: rgba(0, 0, 0, 0.15);
  border: 1px dashed black;
}
