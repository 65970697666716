/**
 * Styles for the <S2Link /> component.
 */

a[href].s2-link {
  &,
  &:hover,
  &:visited:hover {
    color: @s2-link-default;
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: #654cb3;
  }
}
