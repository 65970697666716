html {
  font-family: @s2-font-family;
  /*
   * This is the default root font size used on the Material UI demo site. It seems to be necessary
   * to make the default padding values and sizes of components look right. This value conflicts with
   * the value set in the pdf.js code, which might cause visual problems.
   * https://github.com/mozilla/pdf.js/blob/7dabc5ecc8347fbda8ce4a219c182790d9ec9187/web/viewer.css#L32-L33
   * If it does, styles will either need to be set manually for the Material UI components used by this
   * app, or the pdf.js source code should be changed to reflect this font size.
   */
  font-size: 0.875rem;
}
