/**
 * Alignment of entity creation widgets
 */
.entity-creation-toolbar {
  .entity-creation-toolbar__action-button,
  .entity-creation-toolbar__selection-message {
    vertical-align: bottom;
  }
}

/**
 * Entity creation widget fonts
 */
.entity-creation-toolbar__selection-message {
  font-size: @medium;
}
