/**
 * This file contains global overrides of MaterialUI default styles. Styles in this file can,
 * for example, change the font sizes of Material UI snackbars and tooltips globally.
 */
@{mui-tooltip} {
  font-size: @medium;
}

@{mui-snackbar} {
  &.MuiSnackbar-anchorOriginTopLeft {
    top: @pdfjs-toolbarViewer-height + 10 !important;
  }
}

@{mui-snackbar-message} {
  font-size: @large;
}
