/**
 * Property editor dimensions.
 */
.entity-property-editor {
  width: @entity-property-editor-width;
}

/**
 * Padding between sections.
 */
.entity-property-editor {
  > p,
  > div {
    margin-bottom: @padding * 2;
  }
}

.entity-property-field-container {
  margin-bottom: @padding * 2;

  .entity-property-field {
    margin-bottom: @padding / 2;
  }
}

/**
 * Formatting of rows (field + add/delete buttons) for list-type properties.
 */
.entity-property-field-row {
  display: flex;
  flex-wrap: wrap;

  .entity-property-field {
    display: flex;
    flex: 1 1 auto;
  }
}

/**
 * Span formats.
 */
.entity-property-field-container {
  .action-span {
    color: deepskyblue;
    text-decoration: underline;
    cursor: pointer;
  }
}

/**
 * Buttons for entity fields.
 */
div.entity-property-editor__action-form {
  margin-top: @padding * 4;

  > * {
    width: 100%;
    margin-bottom: @padding;
  }
}

/**
 * Error messages.
 */
.entity-property-editor {
  .entity-property-editor__save-error-label {
    color: @s2-error;
  }
}

p.entity-property-input-error {
  font-size: @small;
  color: @s2-error;
}

/**
 * LaTeX previews.
 */
.rich-text-preview {
  font-size: @medium;
  width: 100%;
  box-sizing: border-box;
  padding: @padding / 2;
  margin-bottom: @padding / 2;

  &__container {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

/**
 * "Preview" label in LaTeX preview.
 */
.rich-text-preview__preview-label {
  float: right;
  background-color: white;
  font-size: @small;
  color: @s2-text-light;
}
