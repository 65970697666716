/**
 * Favorite button placement
 */
.favorite-button {
  position: absolute;
  top: @padding;
  right: @padding;
}

/**
 * Optional opaque configuration
 */
.favorite-button--opaque {
  background-color: #fff;

  .MuiIconButton-root {
    border: @border;
  }
}

/**
 * Container for a favorite must have relative positioning
 */
.favorite-container {
  position: relative;
}

.favorite {
  margin-top: @padding;
}
