/**
 * The base styles for a primer page are the same as those for a pdf.js page:
 * https://github.com/mozilla/pdf.js/blob/67c361b2f4b2ff3ee1c8a90ace643b769e97b85f/web/pdf_viewer.css#L22-L33
 */
.primer-page {
  background-color: white;
  margin: 1px auto -8px auto;
  border: 9px solid transparent;
  background-clip: content-box;
}

/**
 * Layout of content within the primer
 */
.primer-page__contents {
  max-width: 800px;
  margin: 0 auto;
  padding-top: @padding * 5;
  padding-bottom: @padding * 5;
  padding-left: @padding * 6;
  padding-right: @padding * 6;

  /*
   * Distance between child elements
   */
  > * {
    margin-bottom: 1rem;

    & + .primer-page__header {
      padding-top: 1rem;
    }
  }

  ul.feature-list li {
    margin-left: 1.5em;
    margin-bottom: 0.3em;
  }

  a {
    text-decoration: none;
    color: @s2-link-default;

    &:hover {
      color: darken(@s2-link-default, 20);
      text-decoration: underline;
    }

    &:visited {
      color: @s2-link-visited;
      &:hover {
        color: darken(@s2-link-visited, 20);
      }
    }
  }

  /*
   * Arrange glossary in columns
   */
  .primer-page__glossary {
    .gloss {
      width: unset;
      min-width: unset;
      max-width: unset;
      padding: unset;
      margin-bottom: @padding;
    }

    ul {
      column-count: 3;
    }
  }

  hr {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: @padding * 2;
    margin-bottom: @padding * 2;
  }
}

.welcome-page {
  font-family: "Palatino Linotype", "Palatino", "EB Garamond", "Times New Roman",
    "Times", serif;
}

/**
 * Primer fonts
 */
.primer-page__contents {
  font-weight: lighter;
  font-size: @medium;
  color: @s2-text-default;

  .primer-page__header {
    font-size: @large;
    font-weight: bold;
  }

  .primer-page__subheader {
    font-weight: bolder;
  }
}

.primer-page__glossary {
  .gloss {
    width: unset;
    padding: unset;
    font-weight: 300;
    // display: inline-block;
  }

  /*
   * Show glossary entries in columns
   */
  ul {
    // column-count: 3;
    li {
      list-style: none;
      margin-right: @padding;
    }

    /*
     * Don't split an glossary entry between columns. See
     * https://stackoverflow.com/a/7785711/2096369.
     */
    li {
      break-inside: avoid-page;
      .gloss {
        display: block;
        /*
         * Prevents fragmentation of absolutely-positioned children
         * (which is necessary to render KaTeX equations properly). See
         * https://stackoverflow.com/a/57976866/2096369
         */
        transform: translate(0, 0);
      }
    }
  }
}
