/**
 * Tooltip position
 */
.scholar-reader-tooltip {
  position: absolute;
  z-index: @tooltip-z-index;
}

/**
 * Tooltip background
 */
.scholar-reader-tooltip {
  background-color: @white;
  color: @s2-text-default;

  .citation-tooltip-body {
    background-color: @white;
  }
}

/**
 * Tooltip shadow
 */
.scholar-reader-tooltip {
  border: @border;
}
