.citation {
  padding-top: @padding / 2;
  padding-bottom: @padding / 2;
}

/**
 * Title style
 */
.citation .title {
  font-weight: bolder;
}

/**
 * Abstract style
 */
.citation__abstract {
  display: block;
  margin: @medium / 2 0 0;
}

/**
 * Link to sidebar
 */
.citation__abstract__sidebar-link {
  &,
  &:hover,
  &:visited:hover {
    color: @s2-link-default;
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

/**
 * Button for voting on usefulness
 */
.citation-gloss__vote-button {
  position: absolute;
  right: @padding * 2;
  top: @padding * 2;
}
