.document-snippets {
  width: @document-snippets-container-width;
  padding-left: @padding;
  padding-right: @padding;

  > * {
    margin-top: @padding;
  }

  .entity-link-message {
    text-align: center;
    margin-bottom: @padding * 2;
  }
}

/**
 * Formulas
 */
.defining-formula {
  border: @border;
  border-radius: @border-radius;
  padding: @padding;

  /*
   * Interaction
   */
  &.clickable {
    cursor: pointer;
  }

  /*
   * Some equations will be massive, so they scroll.
   */
  max-height: 5rem;
  overflow: hidden;

  /*
   * Don't wrap the text, and center the span if it doesn't take up the whole width.
   */
  .formatted-text {
    white-space: pre;
    display: table;
    margin: 0 auto;
  }
}

.snippet {
  border: @border;
  border-radius: @border-radius;
  padding: @padding;
  overflow: hidden;
  font-size: @medium;
  /*
   * Give lines in snippets a bit of breathing room, as usages frequently
   * inline equations that will make lines taller. By increasing the line
   * height, the line spacing of snippets looks more consistent.
   */
  line-height: 1.5em;
}

/*
 * Links within the document
 */
.entity-page-link {
  white-space: nowrap;
}

.entity-link-span {
  &.clickable {
    text-decoration: underline;
    cursor: pointer;
    color: @s2-link-default;

    &.subtle {
      color: @s2-link-default;
      text-decoration: none;
    }
  }
}
