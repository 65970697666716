/**
 * MathML equation
 */
.mathml {
  display: inline;
}

/**
 * Even if in a Material UI button, don't force it to upper case.
 */
.MuiButton-root .mathml {
  text-transform: none;
}
